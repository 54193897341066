.contactWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 550px;
}

/*.contact-wrapper__margin--h1 {*/
/*    margin-bottom: 29px;*/
/*}*/

/*.contact-wrapper__margin-p {*/
/*    margin-bottom: 15px;*/
/*}*/

.pStrong {
    color: #000;

    font-family: Montserrat, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 31.2px */
}

.pWeak {
    color: #B1B1B1;

    font-family: Montserrat, "sans-serif";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 28.6px */
}

.contactWrapper__image {
    position: relative;
}

.contactWrapper__image {
    position: relative;
}

.contactWrapper__image:hover .airplane {
    animation: fly 2s forwards;
}

.airplane {
    top: 30px;
    right: 20px;
    position: absolute;
}

.contactWrapper__image .airplane {
    animation: backFly 2s backwards;
}

@keyframes fly {
    from {top: 30px; right: 20px; width: 143px; height: 120px }
    to {top: 0px; right: 0px; width: 75px; height: 62px }
}

@keyframes backFly {
    from {top: 0px; right: 0px; width: 75px; height: 62px }
    to {top: 30px; right: 20px; width: 143px; height: 120px }
}
