.SuccessFixed {
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.textFieldContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.submitButton {
    cursor: pointer;
    border-radius: 7.429px;
    background: #FF5C00;
    width: 224px;
    height: 58px;
    padding: 9.905px;
    color: #FFF;

    font-family: Montserrat, "sans-serif";
    font-size: 19.81px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 21.79px */
    border: none;

    margin-top: 54px;
}

.disabledButton {
    cursor: default;
    background-color: #C0C5CC;
}
