.modal {
    position: fixed;
    inset: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}

.overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    padding: 80px;
    border-radius: 10px;
    background: white;
    transition: 0.3s transform;
    transform: scale(0.5);
    max-width: 80%;
    border: 1px solid #C0C5CC;
}

.opened {
    opacity: 1;
    pointer-events: auto;
    z-index: 5;

    .content {
        transform: scale(1);
    }
}

.isClosing {
    .content {
        transform: scale(0.2);
    }
}
