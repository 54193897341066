.careerSection {
    background-color: #F4F4F4;
}

.careerWrapper {
    display: flex;
    flex-direction: column;
    min-height: 1100px;
    justify-content: center;
}

