.textField {
    border-radius: 10px;
    border: 1px solid #C0C5CC;
    background: #FFF;
    padding: 21px 34px;

    color: #C0C5CC;

    font-family: Montserrat, "sans-serif";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 24.2px */

    width: 100%;
}
