.checkboxWrapper {
    position: relative;
    grid-column: 1 / 3;
}

.checkboxWrapper label {
    display: flex;
    align-items: center;
}

.checkboxWrapper span {
    color: #C0C5CC;

    font-family: Montserrat, "sans-serif";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 24.2px */
    cursor: pointer;
}

.checkboxWrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    pointer-events: none;
    cursor: pointer;
    /* creating a custom design */
    width: 28px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #C0C5CC;
    background: #FFF;
    outline: none;
    cursor: pointer;
    margin-right: 19px;
}

input[type="checkbox"]:hover {
    background-color: #FF5C00;
    border: 1px solid #FF5C00;
}

.checkboxWrapper input[type="checkbox"]:checked {
    background-color: #FF5C00;
    border: 1px solid #FF5C00;
}

.checkedImg {
    position: absolute;
    left: 6px;
}

.testL:hover .checkedImg {
    visibility: visible;
}

.testL {
    cursor: pointer;
}

