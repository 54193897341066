.missionWrapperInfo {
    width: 916px;
}

.missionWrapper {
    display: flex;
    flex-direction: column;
    min-height: 1300px;
    justify-content: center;
}


/*.missionWrapper__margin--p {*/
/*    margin-bottom: 90px;*/
/*}*/
